<template>
    <div class="my-5">
        <div :class="{ error: $v[input.codice].$error }">
		
<div 
                v-if="input.tipo == 'select'"
>
<label
                    :for="id ? id : input.id"
                    class="m-0"
		    v-html="input.label + (input.obbligatorio ? '<span class=\'asterisco\'>*</span>' : '')"
                >
                </label>

            <select
                class="form-select w-full"
                aria-label="select"
                @change="$v[input.codice].$model = $event.target.value"
            >
             
                <option
                    v-for="(option, key) in input.valori"
                    :key="option"
                    :selected="key === $v[input.codice].$model ? true : false"
                    :value="key"
                >
                    {{ option }}
                </option>
            </select>
</div>
            <div
                v-else-if="input.tipo == 'history_select'"
                v-tooltip="$t('form.history_select')"
            >
                <Select2
                    :options="input.valori"
                    @change="$v[input.codice].$model = $event"
                    :settings="{ tags: true }"
                    :placeholder="
                        input.label + (input.obbligatorio ? ' *' : '')
                    "
                />
            </div>
            <div v-else-if="input.tipo == 'tel'">
                <div>
                    <vue-phone-number-input
                        v-model="$data[input.codice]"
                        :translations="phoneLabels"
                        :disabled="disabled"
                        size="lg"
                        error-color="red"
                        :style="
                            $v[input.codice].$error
                                ? { border: '1px solid red' }
                                : ''
                        "
                        :id="id ? id : input.id.toString()"
                    />
                </div>
            </div>
            <div v-else-if="input.tipo == 'date' || input.tipo == 'time'">
                <label :for="id ? id : input.id">
                    {{
                        $t(`form.campi.${input.codice} `) ==
                        `form.campi.${input.codice} `
                            ? input.label
                            : $t(`form.campi.${input.codice} `)
                    }}
                    <span v-if="input.obbligatorio" class="asterisco">*</span>
                </label>
                <input
                    :aria-required="input.obbligatorio ? 'true' : 'false'"
                    :id="id ? id : input.id.toString()"
                    :class="{
                        valid: $v[input.codice].$dirty,
                        invalid: $v[input.codice].$error
                    }"
                    :disabled="disabled"
                    :style="
                        $v[input.codice].$error
                            ? { border: '1px solid red' }
                            : ''
                    "
                    :type="input.tipo"
                    placeholder="gg/MM/aaaa"
                    v-model.lazy="$v[input.codice].$model"
                />
            </div>
            <div v-else-if="input.tipo == 'checkbox'" class="form-check ">
                <input
                    :aria-required="input.obbligatorio ? 'true' : 'false'"
                    :id="id ? id : input.id.toString()"
                    :class="{
                        valid: !$v[input.codice].$invalid,
                        invalid: $v[input.codice].$error
                    }"
                    @change="$v[input.codice].$touch()"
                    class="mr-2"
                    :disabled="disabled"
                    :style="
                        $v[input.codice].$error
                            ? { border: '1px solid red' }
                            : ''
                    "
                    type="checkbox"
                    v-model.lazy="$v[input.codice].$model"
                />
                <label
                    :for="id ? id : input.id"
                    class="m-0"
                    v-if="input.codice == 'gdpr'"
                >
                    {{ $t("form.campi.gdpr.text") }}
                    <a :href="getGdprUrl(input)" target="_blank">{{
                        $t("form.campi.gdpr.link")
                    }}</a>
                    <span class="asterisco">*</span>
                </label>
                <label v-else :for="id ? id : input.id"
                    ><span v-html="input.label"></span
                    ><span v-if="input.obbligatorio" class="asterisco"
                        >*</span
                    ></label
                >
            </div>
            <div v-else-if="input.tipo == 'textarea'">
                <label :for="id ? id : input.id"
                    >{{
                        $t(`form.campi.${input.codice} `) ==
                        `form.campi.${input.codice} `
                            ? input.label
                            : $t(`form.campi.${input.codice} `)
                    }}
                    <span v-if="input.obbligatorio" class="asterisco">*</span>
                </label>
                <textarea
                    :aria-required="input.obbligatorio ? 'true' : 'false'"
                    :id="id ? id : input.id.toString()"
                    :class="{
                        valid: !$v[input.codice].$invalid,
                        invalid: $v[input.codice].$error
                    }"
                    :disabled="disabled"
                    style="border-radius: 10px"
                    :style="
                        $v[input.codice].$error
                            ? { border: '1px solid red' }
                            : ''
                    "
                    :type="input.tipo"
                    :placeholder="
                        (input.placeholder || input.label) +
                            (input.obbligatorio ? '*' : '')
                    "
                    v-model.lazy="$v[input.codice].$model"
                    rows="4"
                    cols="100"
                >
                </textarea>
            </div>
            <div v-else class="input-box">
                <input
                    :name="input.codice"
                    :aria-required="input.obbligatorio ? 'true' : 'false'"
                    :id="id ? id : input.id.toString()"
                    :class="{
                        valid: $v[input.codice].$dirty,
                        invalid: $v[input.codice].$error
                    }"
                    :disabled="disabled"
                    :style="
                        $v[input.codice].$error
                            ? { border: '1px solid red' }
                            : ''
                    "
                    :type="input.tipo"
                    :placeholder="
                        $t(`form.campi.${input.codice} `) ==
                        `form.campi.${input.codice} `
                            ? input.placeholder
                            : $t(`form.campi.${input.codice} `)
                    "
                    v-model.lazy="$v[input.codice].$model"
                />
                <label :for="id ? id : input.id"
                    >{{
                        $t(`form.campi.${input.codice} `) ==
                        `form.campi.${input.codice} `
                            ? input.label
                            : $t(`form.campi.${input.codice}`)
                    }}
                    <span v-if="input.obbligatorio" class="asterisco">*</span>
                </label>
            </div>
        </div>
        <div v-if="$v[input.codice].$error || showErrors" class="errors">
            <div
                class="error"
                v-if="
                    ('required' in $v[input.codice] &&
                        !$v[input.codice].required) ||
                        'sameAs' in $v[input.codice]
                "
            >
                ⓘ
                {{ $t("errors.required") }}
            </div>
            <div
                class="error"
                v-if="'email' in $v[input.codice] && !$v[input.codice].email"
            >
                ⓘ
                {{ $t("errors.email") }}
            </div>
            <div
                class="error"
                v-if="
                    'codiceFiscale' in $v[input.codice] &&
                        !$v[input.codice].codiceFiscale
                "
            >
                ⓘ
                {{ $t("errors.codice_fiscale") }}
            </div>
            <div
                class="error"
                v-if="
                    'phoneNumeber' in $v[input.codice] &&
                        !$v[input.codice].phoneNumeber
                "
            >
                ⓘ
                {{ $t("errors.phone") }}
            </div>
            <div
                class="error"
                v-if="'gdpr' in $v[input.codice] && !$v[input.codice].gdpr"
            >
                ⓘ
                {{ $t("errors.gdpr") }}
            </div>
        </div>
    </div>
</template>

<script>
import ValidationRule from "./ValidationRule";

//Input generato in base all'oggetto che viene passato
//il campo viene validato attraverso la classe ValidationRules che analizza l'input e ne determina il tipo di validazione
//@group Inputs
export default {
    props: {
        //Oggetto che rappresenta l'input
        input: {
            type: Object,
            required: true
        },
        id: {
            type: String,
            required: false
        },
        //Mostra o no gli errori
        showErrors: {
            type: Boolean,
            required: false,
            default: false
        },
        //Setta il $v.touch su questo valore
        touch: {
            type: Boolean,
            required: false
        },
        //Campo disabilitato o attivo
        disabled: {
            type: Boolean,
            default: false
        },
        valoreAuth: {
            type: [String, Number]
        },
        codice: {
            type: [String, Number]
        }
    },
    data() {
        {
            let obj = {};
            obj[this.input.codice] = "";
            return obj;
        }
    },
    computed: {
        phoneLabels() {
            const labels = this.$t("phone_input");
            labels.phoneNumberLabel =
                this.$t(`form.campi.${this.input.codice} `) ==
                `form.campi.${this.input.codice} `
                    ? this.input.label
                    : this.$t(`form.campi.${this.input.codice} `);
            if (this.input.obbligatorio)
                labels.phoneNumberLabel = labels.phoneNumberLabel + "*";
            return labels;
        }
    },
    watch: {
        touch: function() {
            if (this.touch) {
                this.$v[this.input.codice].$touch();
            }
        }
    },
    /**
     * @vuese
     * assegna oggetto di validazione all'input
     */
    validations() {
        const obj = {};
        obj[this.input.codice] = new ValidationRule(this.input);
        obj.fetched = false;
        return obj;
    },
    methods: {
        /**
         * @vuese
         * @arg input
         * ritorna l'url del file gdpr
         */
        getGdprUrl(input) {
            if (input.valori.includes("http")) return input.valori;
            return process.env.VUE_APP_SERVER_URL + "/storage/" + input.valori;
        }
    }
};
</script>

<style>
.errors {
    min-height: 25px;
}

.error {
    color: red;
    min-height: 50px;
    /* margin-top: 1rem; */
}

input .error-border {
    border: 3px solid red i !important;
    outline: red;
}

.input-box {
    width: 100%;
    height: 100px;
    position: relative;
}

.input-box input::placeholder {
    opacity: 0;
    transition: 0.3s ease-in;
}

.input-box input,
.input-box label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.input-box label {
    margin-left: 10px;
    transition: 0.4s ease-in;
}

.input-box input:focus::placeholder {
    opacity: 1;
}

/* .input-box input:valid + label {
  color: transparent;
} */
.input-box input:focus + label,
.input-box input.valid + label {
    top: 10px;
}

.input-box input.invalid + label {
    top: 10px;
    color: red;
}

input[type="date"],
input[type="time"] {
    -webkit-appearance: none;
}

.select2 {
    width: 100% !important;
}

.select2 {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.select2-selection__arrow {
    display: none;
}

.select2-container--default .select2-selection--single {
    color: black;
    border: none !important;
}

.select2-container--default
    .select2-selection--single
    .select2-selection__placeholder {
    color: black !important;
    border: none;
}
.error label,
.error input::placeholder,
.asterisco {
    color: red;
}
.error input {
    border-radius: 10px;
}
</style>
